export const NoAlertUrls = [
  '/user/SendPasswordResetEmail',
  '/user/login',
  '/user/ChangePassword',
  '/live/room',
  '/live/room/list',
  '/notifications',
  '/user/vip/login',
  '/user/vip/SendPasswordResetEmail'
]
