const baseSize = 100
function setRem () {
  const scale = document.documentElement.clientWidth / 375
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
setRem()
// let windowHeight = window.innerHeight
// window.onresize = function () {
//   const rootEle = document.getElementById('root')
//   const { height } = rootEle.getBoundingClientRect()
//   if (height > windowHeight) {
//     console.log('onresize', height, windowHeight)
//     rootEle.style.height = height + 'px'
//     windowHeight = height
//   }
// }
// // 解决相对高度弹出软键盘的bug
// setTimeout(() => {
//   const rootEle = document.getElementById('root')
//   const { height } = rootEle.getBoundingClientRect()
//   const designHeight = 667 * document.documentElement.clientWidth / 375
//   const innerHeight = Math.max(height, designHeight)
//   rootEle.style.height = innerHeight + 'px'
//   windowHeight = innerHeight
// })

