import { makeAutoObservable, runInAction } from 'mobx'
import { getCustomizeText } from '@/axios/user'
const xiaoice = [
  'localhost:3000',
  'aibeings-vip-h5-dev.xiaoice.com',
  'aibeings-vip-h5-int.xiaoice.com',
  'aibeings-vip-h5-staging.xiaoice.com',
  'aibeings-vip-h5.xiaoice.com'
]
class ConfigText {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }
  customizeText: any = {
    isCustomize: false,
    // 平台名称
    webName: '',
    // 标签名称
    h5TabTitle: '',
    // 服务邮箱
    webServiceEmail: '',
    // web自定义域名
    webDomain: '',
    //
    chineseText: '',
    englishText: ''
  }
  getConfigText = async () => {
    const domain = window.location.host
    // const domain = 'ceshi:3000'
    const isXiaobingHost = xiaoice.includes(domain)
    if (isXiaobingHost) {
      return
    }
    const { data = {} } = await getCustomizeText({ domain })
    runInAction(() => {
      document.title = data?.h5TabTitle
      this.customizeText = {
        isCustomize: true,
        ...data
      }
    })
  }
}
export default new ConfigText()
