/** 上报的事件名(小驼峰) */
export enum ETrackingEventName {
  /** 页面 pv, 以 location.pathname 变化为一次(eventValue 取值 location.pathname)  */
  pv = 'pv',
  /** 清空当前任务(eventValue 取值 button、modal,分别表示点击清除按钮、点击二次确定弹窗的确定) */
  clearCurrentTask = 'clearCurrentTask',
  /** 点击模版,在工作台中(eventValue 取值模版id) */
  clickTemplate = 'clickTemplate',
  /** 点击背景(eventValue 取值背景id) */
  clickBackground = 'clickBackground',
  /** 点击背景分类(eventValue 取值分类mediaTagName) */
  clickBackgroundTag = 'clickBackgroundTag',
  /** 点击贴纸(eventValue 取值贴纸id) */
  clickSticker = 'clickSticker',
  /** 点击贴纸分类(eventValue 取值分类mediaTagName) */
  clickStickerTag = 'clickStickerTag',
  /** 点击音乐(eventValue 取值音乐id) */
  clickMusic = 'clickMusic',
  /** 点击音乐分类(eventValue 取值分类mediaTagName) */
  clickMusicTag = 'clickMusicTag',
  /** 点击员工属性 icon(eventValue 为 0,1.表示点击前员工属性弹窗的显隐,1 为显示,0 为隐藏) */
  clickEmployeeAttributeIcon = 'clickEmployeeAttributeIcon',
  /** 员工属性弹窗中点击形象(eventValue 为 personaBizId) */
  clickEmployeeAttributeFigure = 'clickEmployeeAttributeFigure',
  /** 员工属性弹窗中发音-语言切换(eventValue 为语言值) */
  selectEmployeeAttributeLanguage = 'selectEmployeeAttributeLanguage',
  /** 员工属性弹窗中发音-音色切换(eventValue 为音色值) */
  selectEmployeeAttributeTimbre = 'selectEmployeeAttributeTimbre',
  /** 员工属性弹窗中点击确定 */
  clickEmployeeAttributeSure = 'clickEmployeeAttributeSure',
  /** 点击下载视频(eventValue 为下载地址) */
  clickDownloadVideo = 'clickDownloadVideo',
  /** 点击下载音频(eventValue 为下载地址) */
  clickDownloadAudio = 'clickDownloadAudio',
  /** 点击试听按钮 */
  clickTryListen = 'clickTryListen',
  /** 试听时长 */
  durationTryListen = 'durationTryListen',
  /**
   * 上传视频
   *    eventValue 取值
   *      - videoInEditor (编辑器中的视频-自定义)
   *      - bgVideoInEditor (编辑器中的背景视频-自定义)
   *      - videoInMaterial (素材库中的视频)
   *      - bgVideoInMaterial (素材库中的背景视频)
   */
  uploadVideo = 'uploadVideo',
  /** 改变音乐音量(eventValue 取值 drag、input,分别表示拖拽、输入) */
  changeMusicVolume = 'changeMusicVolume',
  /** 改变图层显隐(eventValue 取值 0、1,分别表示展示、隐藏) */
  changeCoverageShow = 'changeCoverageShow',
  /** 改变图层锁定(eventValue 取值 0、1,分别表示取消、锁定) */
  changeCoverageLock = 'changeCoverageLock',
  /** 改变图层拖拽 */
  changeCoverageDrag = 'changeCoverageDrag',
  /** ssml 的停顿 */
  clickSSMLPause = 'clickSSMLPause',
  /** ssml 的分词 */
  clickSSMLParticiple = 'clickSSMLParticiple',
  /** ssml 的数字读法 */
  clickSSMLNumber = 'clickSSMLNumber',
  /** ssml 的多音字 */
  clickSSMLPolyphone = 'clickSSMLPolyphone',
  /** ssml 的替换发音 */
  clickSSMLReplace = 'clickSSMLReplace',
  /** 首页列表摘要视频(人设)的播放次数(鼠标移入触发播放).(eventValue 为人设 personaBizId) */
  personSummaryVideoPlayCount = 'personSummaryVideoPlayCount',
  /** 项目视频播放次数(eventValue 为人设 personaBizId) */
  personProjectVideoPlayCount = 'personProjectVideoPlayCount',
  /** 打开播报工作台(eventValue 为json:人设 personaBizId; type 取值 model、list,分别表示首页弹窗进入、项目列表进入) */
  openVideoWork = 'openVideoWork'
}

/** eventValue 的固定值(写清注释) */
export const EVENT_VALUE_FIXED = {
  /** 背景/ 分类中的'全部' */
  all: '全部'
}

/** 单个事件信息(新增的字段都需要确定后端增加) */
export interface IEventTrackingEvent {
  /** 事件名称.小驼峰 */
  eventName: ETrackingEventName
  /** 事件的值 */
  eventValue: string
  /** 次数(时长类的 eventSize 代表时长) */
  eventSize: number
  /** 毫秒时间戳 */
  eventTimestamp: number
  // 新增的字段在下面添加,应该是可选的
}
