import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import dayjs from 'dayjs'

import { ENV_CONFIG } from '@/constants/config'
import { EReportModule } from './constants'
export { EReportModule }

const isOpenSentry = !ENV_CONFIG.IS_LOCAL

const release = process.env.GIT_HASH
const environment = ENV_CONFIG.PROJECT_ENV
const time = process.env.BUILD_TIME
  ? dayjs(Number(process.env.BUILD_TIME)).format('YYYY.MM.DD HH:mm')
  : process.env.BUILD_TIME

/** 初始化 */
const init = () =>
  Sentry.init({
    dsn: 'https://048d29051e4d4b8f81be4f2878ee1911@aic-sentry.trafficmanager.cn/59',
    integrations: [new Integrations.BrowserTracing()],
    release,
    environment,
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    initialScope: {
      tags: {
        buildTime: time
      }
    },
    // 保留原代码
    beforeSend: (event) => {
      if (event.exception?.values) {
        const first = event.exception.values[0]
        if (first) {
          if (first.value?.includes('Loading chunk')) return null
          if (first.value?.includes('[info]:')) {
            event.level = Sentry.Severity.Info
          }
          if (first.value?.includes('[warning]:')) {
            event.level = Sentry.Severity.Warning
          }
        }
      }
      return event
    }
  })

/** 设置用户信息 */
const setUser = (user: any) => {
  Sentry.setUser({
    user
  })
}

interface IReportBaseConfig {
  level: 'fatal' | 'error' | 'warning' | 'info'
  /** 模块名 */
  module: EReportModule
  /** 额外信息(自定义) */
  extra?: Record<string, any>
  /** 关键词标识(方便代码搜索) */
  keyword: string
}

/** 基础主动捕获错误 */
const reportBase = (config: IReportBaseConfig, error?: Error) => {
  const { module, keyword, extra, level } = config

  const newError = error || new Error(level)
  if (newError) {
    newError.message = `[${module}]${keyword}: ${newError.message}`
  }
  Sentry.captureException(newError, {
    level: level as Sentry.Severity,
    tags: {
      module
    },
    extra
  })
}

type TReportCommonConfig = Pick<IReportBaseConfig, 'module' | 'keyword' | 'extra'>

/** sentry 错误上报封装 */
const report = {
  fatal: (config: TReportCommonConfig, error: Error) =>
    reportBase(
      {
        level: 'fatal',
        ...config
      },
      error
    ),
  error: (config: TReportCommonConfig, error: Error) =>
    reportBase(
      {
        level: 'error',
        ...config
      },
      error
    ),
  warning: (config: TReportCommonConfig, error?: Error) =>
    reportBase(
      {
        level: 'warning',
        ...config
      },
      error
    ),
  info: (config: TReportCommonConfig) =>
    reportBase({
      level: 'info',
      ...config
    })
}

const sentryInfo = {
  init,
  setUser,
  report
}

type TSentryInfo = Partial<typeof sentryInfo>

const sentry = isOpenSentry ? sentryInfo : ({} as TSentryInfo)
// 如果需要本地环境上报错误则打开下面注释
// const sentry = sentryInfo

/** 初始化打印信息 */
console.log(
  '==>projectInfo: isOpenSentry:%s, gitHash:%s, environment:%s, buildTime:%s',
  isOpenSentry,
  release,
  environment,
  time
)

export default sentry
