import eventTracking from '@/plugins/eventTracking'
import { ENV_CONFIG } from '@/constants/config'

/** 埋点初始化配置并 ready */
export const eventTrackingInitAndReady = (userId: string) => {
  // 开始本地 debug 模式
  // eventTracking.setIsDebug(true)
  eventTracking.setGlobalConfig({
    fromDomain: location.host,
    userId,
    env: ENV_CONFIG.PROJECT_ENV,
    appCode: 3
  })

  // 如果需要本地真实上报,则注释掉这个判断
  if (ENV_CONFIG.IS_LOCAL) {
    return
  }

  eventTracking.setIsReady(true)
}
