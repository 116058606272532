import configData from '@/config'
import service from './service'

interface LoginRequest {
  userName?: string
  password?: string
  phone?: string
  smsCode?: string
}

interface LoginResponse {
  refreshToken: string
  accessToken: string
}

export function login(params: LoginRequest) {
  return service<LoginResponse>({
    method: 'POST',
    url: '/user/vip/login',
    data: params
  })
}

export function resetPass(email: string) {
  return service<any>({
    method: 'GET',
    url: '/user/vip/SendPasswordResetEmail',
    params: { email }
  })
}

export function getTokenByRefreshToken(refreshToken: string) {
  return service<LoginResponse>({
    method: 'GET',
    url: '/refreshToken',
    params: {
      refreshToken
    }
  })
}

export function getUserInfo() {
  return service({
    method: 'GET',
    url: '/user/info'
  })
}

interface EmailRequest {
  email: string
  confirmToken: string
  newPassword: string
  confirmPassword: string
}

export function createPasswordByEmail(data: EmailRequest) {
  return service({
    method: 'POST',
    url: '/user/EmailConfirmAddCreatePassword',
    data
  })
}

interface ResetEmailRequest {
  email: string
  resetToken: string
  newPassword: string
  confirmPassword: string
}

export function resetPasswordByEmail(data: ResetEmailRequest) {
  return service({
    method: 'POST',
    url: '/user/PasswordReset',
    data
  })
}

interface UserLawSignRequest {
  licenceUpdateTime: string
  licenceValidTime: string
  licenceName: string
}

export function userLawSign(data: UserLawSignRequest[]) {
  return service({
    method: 'POST',
    url: '/user/sign',
    data
  })
}

export function loginWithCreateCookie(token: string) {
  return service({
    method: 'POST',
    url: 'user/login-with-create-token?token=' + token
  })
}

function flattenOEMInfo(data: any) {
  const resData: any = {}
  for (const [key, value] of Object.entries(data)) {
    if (key === 'baseExtItems' && value) {
      for (const [k, v] of Object.entries(value as object)) {
        resData[k] = v
      }
    } else if (key === 'webExtItems' && value) {
      for (const [k, v] of Object.entries(value as object)) {
        resData[k] = v
      }
    } else if (key === 'h5ExtItems' && value) {
      for (const [k, v] of Object.entries(value as object)) {
        resData[k] = v
      }
    } else {
      resData[key] = value
    }
  }
  return resData
}

// 获取自定义文字
export function getCustomizeText(data: { domain: string }) {
  return service({
    method: 'POST',
    url: 'company/oem/getInfo',
    data
  }).then(res => {
    if (res.data) {
      res.data = flattenOEMInfo(res.data)
    }
    return res
  })
}

export function sendSmsCode(data: any) {
  return service({
    method: 'POST',
    url: '/user/sendLoginSmsCode',
    data
  })
}

export function loginByAuth(authCode: string, authSource = 'dingtalk') {
  return service({
    method: 'POST',
    url: '/user/loginByAuth',
    data: {
      authCode,
      authSource,
      loginMethod: 1
    }
  })
}

export function getCompanyValidDate() {
  return service({
    method: 'GET',
    url: '/company/getValidDate'
  })
}

export function activateByCode(code: string, companyName: string) {
  return service({
    method: 'GET',
    url: '/company/activateByCode',
    params: {code, companyName}
  })
}
