/** 项目环境.这个变量不用导出,如果使用请使用 ENV_CONFIG */
const PROJECT_ENV = process.env.PROJECT_ENV

/** 项目环境配置 */
export const ENV_CONFIG = {
  /** 项目运行环境(local、dev、int、prod) */
  PROJECT_ENV,
  /** 是否为本地运行,即 PROJECT_ENV 为 local */
  IS_LOCAL: process.env.IS_LOCAL,
  /** 是否为 int 环境 */
  IS_INT: PROJECT_ENV === 'int',
  /** 是否为线上环境 */
  IS_PROD: PROJECT_ENV === 'prod'
}
