import { IEventTrackingEvent } from './constants'
import { postEventTracking, IPostEventTrackingReport } from './service'
export { ETrackingEventName, EVENT_VALUE_FIXED } from './constants'

/** 上报信息的全局配置 */
type TEventTrackingGlobalConfig = Pick<IPostEventTrackingReport, 'fromDomain' | 'appCode' | 'env' | 'userId'>

/**
 * 开发人员调用上传的数据
 *    时间戳 eventTimestamp 是由 EventTracking 类中统一处理
 */
type TTRackData = Partial<Pick<IEventTrackingEvent, 'eventValue' | 'eventSize'>> &
  Pick<IEventTrackingEvent, 'eventName'>

/** 开发人员调用上传持续时间段统计的参数 */
interface ITrackDurationConfig {
  /** 时长类的 eventSize 是通过计算获取 */
  data: Omit<TTRackData, 'eventSize'>
  isImmediate?: boolean
  /** 单个事件名下的唯一id,用于计算时间差值,eventValue重复时使用.默认是使用 eventValue 生成 */
  uuidInEventName?: string | number
  /** 是否结束,在结束时会上报事件 */
  isEnd: boolean
}

/** 开发人员调用上传的数据的参数 */
interface ITrackConfig {
  data: TTRackData
  isImmediate?: boolean
}

/** 上报持续时间的类型 */
interface IDurationItem {
  /** 开始的时间戳 */
  start: number
  /** 结束的时间戳 */
  end: number
}

const DEFAULT_PART_DATA: Pick<IEventTrackingEvent, 'eventValue' | 'eventSize'> = {
  eventValue: '',
  eventSize: 1
}

/** 埋点的上报策略 */
const EVENT_TRACKING_REPORT_STRATEGY = {
  /** 事件缓存的数量 */
  eventCacheAmount: 10,
  /** 事件最晚上报时间.单位 s,即单个事件插入事件数组后,最晚多久后上报(即使没有满足缓存的数量) */
  eventReportLatestTime: 30,
  /** 上报时长时的最大值(单位 ms) 避免脏数据(暂定 1 d) */
  maxDuration: 24 * 60 * 60 * 1000
}

/** 事件埋点 */
class EventTracking {
  /** 是否开启调试模式 */
  private isDebug = false
  /** 上报信息的全局配置 */
  private globalConfig?: TEventTrackingGlobalConfig
  /** 是否准备好(前提是已获取依赖信息,如全局信息) */
  private isReady = false
  /** 事件缓存数组 */
  private eventsCache: IEventTrackingEvent[] = []
  /** 事件最晚上报时间的定时器 */
  private eventReportLatestTimer?: NodeJS.Timeout
  /** 持续时间类型的数据信息 */
  private durationMap: Record<string, Record<string, IDurationItem>> = {}
  /** 日志打印 */
  private debugLog(...args: any[]) {
    this.isDebug && console.log('==>eventTracking', ...args)
  }

  /** 是否开启调试 */
  setIsDebug(isDebug: boolean) {
    this.isDebug = isDebug
  }

  /** 设置全局信息(一般只调用一次) */
  setGlobalConfig(config: TEventTrackingGlobalConfig) {
    this.globalConfig = config
    this.debugLog('setGlobalConfig 设置全局信息', this.globalConfig)
  }

  /** 设置是否准备好 */
  setIsReady(isReady = true) {
    this.isReady = isReady

    // 设置为准备好后,如果有缓存,则上报
    if (this.isReady) {
      this.debugLog('ready 后的缓存事件', this.eventsCache)

      this.eventReport()
    }
  }

  /**
   * 事件请求
   * 根据事件数据和 globalConfig 进行拼接,然后请求,不修改 eventsCache
   */
  private eventRequest(events: IEventTrackingEvent[]) {
    if (!(this.isReady && this.globalConfig && events?.length)) {
      this.debugLog('eventRequest 未生效', '请求前请配置 globalConfig 和 isReady 设为 true')
      return
    }

    const globalConfig = this.globalConfig
    const reports: IPostEventTrackingReport[] = events.map((el) => ({
      ...globalConfig,
      event: el
    }))

    this.debugLog('eventRequest 发送请求', reports)

    // 异步请求
    postEventTracking({ reports })
  }

  /**
   * 按策略进行事件上报
   * 上报时机:
   *  1. 缓存数量已满
   *  2. 最晚上报时间到
   */
  private eventReport() {
    if (!(this.isReady && this.globalConfig)) {
      this.debugLog('eventReport 未生效', '上报前请配置 globalConfig 和 isReady 设为 true')
      return
    }

    // 如果缓存数量已满,则上报
    if (this.eventsCache.length >= EVENT_TRACKING_REPORT_STRATEGY.eventCacheAmount) {
      // 上报并清空事件缓存数组
      this.eventRequest(this.eventsCache)
      this.eventsCache = []
    } else {
      // 如果未满,则设置最晚上报时间
      this.eventReportLatestTimer && clearTimeout(this.eventReportLatestTimer)
      this.eventReportLatestTimer = setTimeout(() => {
        // 上报并清空事件缓存数组
        this.eventRequest(this.eventsCache)
        this.eventsCache = []
      }, EVENT_TRACKING_REPORT_STRATEGY.eventReportLatestTime * 1000)
    }
  }

  /** 事件增加 */
  private eventAdd(event: IEventTrackingEvent) {
    this.eventsCache.push(event)

    this.eventReport()
  }

  /**
   * 对外暴露的埋点上报方法(用于一次就能收集数据)
   *  isImmediate 设为 true 时代表立刻上报,非特殊场景不建议使用
   */
  track(config: ITrackConfig) {
    const { data, isImmediate = false } = config
    if (!data) {
      this.debugLog('track 未生效', '没有配置上报数据 data')
      return
    }

    // 立刻请求上报,单独逻辑
    if (isImmediate) {
      const immediateEvent: IEventTrackingEvent = {
        ...DEFAULT_PART_DATA,
        ...data,
        eventTimestamp: Date.now()
      }
      this.debugLog('track 立刻上报', immediateEvent)

      this.eventRequest([immediateEvent])
      return
    }

    // 走批量缓存逻辑
    const delayEvent: IEventTrackingEvent = {
      ...DEFAULT_PART_DATA,
      ...data,
      eventTimestamp: Date.now()
    }
    this.debugLog('track 延迟上报', delayEvent)

    this.eventAdd(delayEvent)
  }

  /**
   * 对外暴露的持续时间类型的埋点上报方法(用于 2 次才能收集数据,比如播放时长)
   * isImmediate 设为 true 时代表立刻上报,非特殊场景不建议使用
   */
  trackDuration(config: ITrackDurationConfig) {
    const { data, isImmediate = false, uuidInEventName, isEnd } = config

    const uuid = uuidInEventName || (data.eventValue as string)

    if (!this.durationMap[data.eventName]) {
      this.durationMap[data.eventName] = {}
    }

    // 起始点
    if (!isEnd) {
      this.durationMap[data.eventName][uuid] = {
        start: Date.now(),
        end: 0
      }
      return
    }

    // 结束,上报
    if (isEnd && this.durationMap[data.eventName][uuid]?.start) {
      const duration = Date.now() - this.durationMap[data.eventName][uuid].start

      // 过滤掉超过无效的超大的脏数据
      if (duration > 0 && duration <= EVENT_TRACKING_REPORT_STRATEGY.maxDuration) {
        this.track({
          data: {
            ...data,
            // 时长类的 eventSize 代表时长
            eventSize: duration
          },
          isImmediate
        })

        this.durationMap[data.eventName][uuid] = {
          start: 0,
          end: 0
        }
      }
    }
  }
}

/** 全局只有一个实例化 */
const eventTracking = new EventTracking()

export default eventTracking
