import React from 'react'
import sentry, { EReportModule } from '@/plugins/sentry'

interface IProps {
  children?: React.ReactNode
}

export default class ErrorBoundary extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('==>白屏', error, errorInfo)
    sentry?.report?.fatal(
      {
        module: EReportModule.Global,
        keyword: '白屏',
        extra: {
          errorInfo
        }
      },
      error
    )
  }

  render() {
    return this.props?.children
  }
}
