import { action, makeObservable, observable } from 'mobx'
import sentry from '@/plugins/sentry'
import { eventTrackingInitAndReady } from '@/plugins/eventTracking/utils'

const USER_INFO_NAME = 'USER_INFO'

export interface UserInfo {
  activated: boolean
  avatar: string
  company: {
    id: string
    displayName: string
    codeName: string
    streamingPullAddress: string
    subscriptionServices: string[]
    validEndTime: string
  }
  properties: [{authSource: string}]
  displayName: string
  id: string
  lockedout: boolean
  phoneNumber: string
  rights: string[]
  roles: Array<{ id: string; name: string }>
  userName: string
  displayNamePinyin: string
  email: string
}

class UserStore {
  constructor() {
    makeObservable(this)
    this.init()
  }

  @observable info: UserInfo | null = null

  @observable loginInfo: {
    email: string | undefined
    password: string | undefined
  } | null = null

  @action setInfo(info: Partial<UserInfo> | null) {
    if (!info) {
      window.localStorage.removeItem(USER_INFO_NAME)
      this.info = null
    } else {
      const data = { ...this.info, ...info }
      window.localStorage.setItem(USER_INFO_NAME, JSON.stringify(data))
      this.info = data as UserInfo
      eventTrackingInitAndReady(this.info?.id)
      sentry.setUser?.(this.info)
    }
  }

  @action setLoginInfo(info: any) {
    this.loginInfo = info
  }

  init = () => {
    const getUserInfo = () => {
      try {
        if (window.localStorage?.getItem(USER_INFO_NAME)) {
          return JSON.parse(window.localStorage?.getItem(USER_INFO_NAME) as string)
        }
        return null
      } catch {
        return null
      }
    }
    const info = getUserInfo()
    this.info = info
    if (info) {
      eventTrackingInitAndReady(info?.id)
      sentry.setUser?.(this.info)
    }
  }
}

export default new UserStore()
