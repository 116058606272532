import axios from 'axios'
import userStore from '@/stores/user'
import { customHistory } from '@/routes/RootRouter'
import { getTokenByRefreshToken } from './user'
import { Toast } from 'antd-mobile'
import { NoAlertUrls } from '@/axios/noAlert'
import sentry, { EReportModule } from '@/plugins/sentry'
import { delCookie } from '@/utils/cookie'
import configData from '@/config'
import { isMobile } from '@/utils/utils'
const service = axios.create({
  baseURL: configData?.isCU ? '/h5/api/v1' : '/api/v1',
  withCredentials: true,
  headers: {
    'Client-Type': isMobile() ? 'H5' : 'PC',
    'Client-version': '23.11.14'
  }
})

service.interceptors.request.use(
  (config) => {
    // if (userStore.info?.accessToken) {
    //   config.headers.Authorization = `Bearer ${userStore.info.accessToken}`;
    // }
    if (config.url?.includes('/v1')) {
      return {
        ...config,
        url: config.url.replace('/v1', '')
      }
    } else {
      return config
    }
  },
  (error) => {
    sentry?.report?.error(
      {
        module: EReportModule.Api,
        keyword: '请求前报错',
        extra: {
          error
        }
      },
      error
    )

    return Promise.reject(error)
  }
)
// 防止用户登录失效信息弹出多个
let loginMsgShow = false
service.interceptors.response.use(
  async (response) => {
    if (
      (response.data.hasOwnProperty('message') || response.data.hasOwnProperty('msg')) &&
      response.data.hasOwnProperty('code')
    ) {
      if (response.data.code === 200 || response.data.code === 0) {
        return response.data.data
      } else {
        if (!NoAlertUrls.includes(response?.config?.url || '')) {
          Toast.show({
            content: response.data.message
          })
        }

        sentry?.report?.error(
          {
            module: EReportModule.Api,
            keyword: `请求业务报错 ${response?.config?.url}`,
            extra: {
              /** 响应的整体 */
              response,
              /** 后端返回的整体 */
              responseData: JSON.stringify(response?.data)
            }
          },
          new Error(response?.data?.message || '')
        )

        return Promise.reject(`[info]: ${JSON.stringify(response.data)}`)
      }
    } else {
      return response.data
    }
  },
  async (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      return Promise.reject(error)
    }
    if (error?.response?.status === 401) {
      // if (noNeedJurisdiction()) return
      if (!loginMsgShow) {
        loginMsgShow = true
        // Toast.show({
        //   content: '用户信息失效，请重新登录'
        // })
        localStorage.removeItem('isLogin')
        delCookie('isLogin')
        delCookie('loginSessionId')
        setTimeout(() => {
          loginMsgShow = false
        }, 2000)
      }
      userStore.setInfo(null)
      customHistory.push('/login')
      return Promise.reject(error)
    } else if (error?.response?.status === 403) {
      if (!NoAlertUrls.includes(error?.config?.url || '')) {
        Toast.show({
          content: '无权限访问'
        })
      }
      return Promise.reject(error)
    } else {
      if (!NoAlertUrls.includes(error?.config?.url || '')) {
        if (error?.response?.data?.hasOwnProperty('message')) {
          Toast.show({
            content: error?.response?.data.message
          })
        } else {
          Toast.show({
            content: '服务器开小差啦，请稍后再试'
          })
        }
      }

      sentry?.report?.error(
        {
          module: EReportModule.Api,
          keyword: `请求网络报错 ${error?.config?.url || ''}`,
          extra: {
            error,
            config: error?.config,
            request: error?.request,
            response: error?.response,
            isAxiosError: error?.isAxiosError
          }
        },
        error
      )

      return Promise.reject(error)
    }
  }
)

export default service
