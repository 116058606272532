/**中国联通接口*/

import service from './service'

/**登出*/
export function logout() {
  return service({
    method: 'POST',
    url: '/unicom/logout'
  })
}

/**注册登录*/
export function cuLogin(data: { phone: string; validCode: string }) {
  return service({
    method: 'POST',
    url: '/unicom/register-login',
    data
  })
}

/**获取登录验证码*/
export function getLoginCode(phone: string) {
  return service({
    method: 'POST',
    url: '/unicom/valid-code',
    data: {
      phone
    }
  })
}

/**验证登录token有效性*/
export function loginWithTokenUn(data: any) {
  return service({
    method: 'POST',
    url: '/unicom/login-with-token',
    data
  })
}
