/*
 * @Author: wuxian wuxian@xiaoice.com
 * @Date: 2023-11-14 16:16:12
 * @LastEditors: wuxian wuxian@xiaoice.com
 * @LastEditTime: 2023-11-14 16:20:23
 * @FilePath: /h5-Big-gift-bag/src/config.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getMd5_16 } from '@/utils/utils'

//本地中国联通   yarn start:CU

//中国联通hostname
const CHINA_UNION = 'universe.10155.com'
let hostname =
  window.location.hostname === 'localhost'
    ? 'aibeings-vip-h5-dev.xiaoice.com'
    : window.location.hostname.includes('unihuman') ||
      window.location.hostname.includes('universe') ||
      window.location.hostname.includes('10155')
    ? CHINA_UNION
    : window.location.hostname

const isCU =
  process.env.REACT_APP_COMPANY === 'CU' ||
  hostname === CHINA_UNION ||
  hostname.includes('unihuman') ||
  hostname.includes('universe') ||
  hostname.includes('10155')
//const hostname = window.location.hostname === 'localhost' ? 'www.test.com' : window.location.hostname;
hostname = isCU ? CHINA_UNION : hostname
const md5 = getMd5_16(hostname)

// const md5 = '4c422cdda4bbce9a'

const config = {
  customize: {
    isCustomize: true,
    /**是否是中国联通项目*/
    isCU: isCU,
    favicon: `https://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-favicon-icon.png`,
    title: '员工定制',
    // 服务条款
    service_terms: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-service-terms.png`,
    // 隐私协议
    privacy_policiest: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-privacy-policiest.png`,
    login_logo: isCU
      ? require('./images/login/logo.svg').default
      : `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-login-logo.png`,
    about_logo: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-about-logo.png`,
    video_agreement: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-video_agreement.png`,
    audio_agreement: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-audio_agreement.png`,
    logo: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-logo.png`,
    h5_character_custom_note: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-character-custom-note.png`,
    h5_character_upload_note: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-character-upload-note.png`,
    h5_character_custom_tutorial_video: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-character-custom-tutorial-video.mp4`,
    h5_voice_custom_note: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-voice-custom-note.png`,
    h5_voice_custom_tutorial_video: `http://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/${md5}-h5-voice-custom-tutorial-video.mp4`,
  },
  default: {
    favicon: require('./images/common/favicon.svg').default,
    title: '员工定制',
    login_logo: require('./images/login/logo.svg').default,
    about_logo: require('./images/my/about-logo.svg').default,
    logo: require('./images/password/pwd-logo.png').default,
    h5_character_custom_note: require('./images/make-video/h5-character-custom-note.png').default,
    h5_character_upload_note: require('./images/make-video/h5-character-upload-note.png').default,
    h5_character_custom_tutorial_video: `https://virtualman.oss-cn-beijing.aliyuncs.com/media_upload/video/3fd53e1c67284e39af307a058c51a7b4.mp4`,
    h5_voice_custom_note: require('./images/make-audio/h5-voice-custom-note.png').default,
    h5_voice_custom_tutorial_video: `https://virtualman.oss-cn-beijing.aliyuncs.com/media_upload/video/19064494-1ebb-4724-b842-8e074a408f71`,
  }
}
const xiaoice = [
  'aibeings-vip-h5-dev.xiaoice.com',
  'aibeings-vip-h5-int.xiaoice.com',
  'aibeings-vip-h5-staging.xiaoice.com',
  'aibeings-vip-h5.xiaoice.com'
]
// const configData = config[process.env.REACT_APP_COMPANY || "default"];
const isXiaobingHost = xiaoice.includes(hostname)
// const isXiaobingHost = false
const configData = config[isXiaobingHost ? 'default' : 'customize'] as any
;(function () {
  const link = (document.querySelector("link[rel*='icon']") || document.createElement('link')) as HTMLLinkElement
  link.type = 'image/png'
  link.rel = 'shortcut icon'
  link.href = configData.favicon
  // link.href = 'https://livestream-admin.oss-cn-beijing.aliyuncs.com/oem/adc505c9921b5f3c-favicon-icon.png'
  document.getElementsByTagName('head')[0].appendChild(link)
})()
export default configData
// module.exports = configData;
