import { Suspense, useEffect } from 'react'
import { getCookie, delCookie } from '@/utils/cookie'
import { useSetState } from '@/hooks'
import { Route, Switch, Router, Redirect } from 'react-router-dom'
import * as history from 'history'
import userStore, { UserInfo } from '@/stores/user'
import { RouteOptions, routes } from './index'
import { getUserInfo } from '@/axios/user'
import { useHistory } from 'react-router'
import { useStore } from '@/stores'
import dayjs from 'dayjs'

import config from '@/config'
import { loginWithTokenUn } from '@/axios/ChinaUnion'
export interface RouteComponent {
  routes: RouteOptions[]
}

export const customHistory = history.createBrowserHistory({
  basename: config?.isCU ? '/h5' : ''
})

export function WithRouteHook(props: any) {
  const [state, setState] = useSetState({
    init: false
  })
  const history = useHistory()
  const token = new URLSearchParams(window.location.search).get('token') || ''
  const loginFn = async () => {
    if (config?.isCU && token) {
      await loginWithTokenUn({ loginToken: token })
    } else {
      // 首次访问的用户从来没有登录过，不应该弹出登录失效的消息
      if (!(localStorage?.getItem('isLogin') || getCookie('isLogin') === 'true')) {
        history.push('/login')
        return
      }
    }

    if (!userStore.info) {
      getUserInfo().then(
        (info: UserInfo) => {
          userStore.setInfo(info)
          if (info.properties?.[0]?.authSource === 'dingtalk') {
            const validEndTime = dayjs(info.company?.validEndTime, 'YYYY-MM-DD hh:mm:ss')
            const now = dayjs()
            if (now > validEndTime) {
              history.replace('/code')
              return
            }
          }
          setState({
            init: true
          })
          localStorage.setItem('isLogin', 'true')
        },
        () => {
          // info 403重登录
          localStorage.removeItem('isLogin')
          delCookie('isLogin')
          delCookie('loginSessionId')
          history.push('/login')
        }
      )
    } else {
      setState({
        init: true
      })
    }
  }
  useEffect(() => {
    loginFn()
  }, [])
  return state.init && props.children
}

export function renderRoutes(routes: RouteOptions[]) {
  const { getConfigText } = useStore('configText')
  useEffect(() => {
    if (getConfigText) {
      getConfigText()
    }
  }, [])
  return (
    <>
      {routes.map((config, index) => {
        const { component: Component, routes, ...rest } = config
        return (
          <Route
            key={`${config.path}${index}`}
            {...rest}
            render={(props) => {
              if (config.redirect && config.path === location.pathname) {
                return <Redirect to={config.redirect} exact />
              }
              // if (config.auth && !userStore.info) {
              //   return <Redirect to="/login" exact />
              // }
              if (Component) {
                if (config.auth) {
                  return (
                    <WithRouteHook>
                      <Component {...props} {...(routes ? { routes } : {})} />
                    </WithRouteHook>
                  )
                }
                return <Component {...props} {...(routes ? { routes } : {})} />
              }
            }}
          />
        )
      })}
    </>
  )
}

export default function RootRouter() {
  return (
    <Router history={customHistory}>
      <Switch>
        <Redirect from="/" to={'home/custom'} exact />
        <Suspense fallback={<div />}>{renderRoutes(routes)}</Suspense>
        <Redirect to={'/'} exact />
      </Switch>
    </Router>
  )
}
