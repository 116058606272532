export const getCookie = (name: string) => {
  let arr
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) return unescape(arr[2])
  else return null
}

export const delCookie = (name: string) => {
  const exp = new Date()
  exp.setTime(exp.getTime() - 1)
  const cval = getCookie(name)
  if (cval != null) document.cookie = name + '=' + cval + ';expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export const setCookie = (name: string, value: any) => {
  const Days = 30
  const exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + escape(value) + ';expires=' + exp.toUTCString()
}
