import axios from 'axios'

import { IEventTrackingEvent } from './constants'

/** 请求的单个 report 类型 */
export interface IPostEventTrackingReport {
  /** 域名.用来区分oem, 例 abc.xxx.com */
  fromDomain: string
  /** 应用标识.区分业务领域,线下找后端获取 */
  appCode: number
  /** 环境.如 dev、int、prod */
  env: string
  /** 用户唯一标识 */
  userId: string
  /** 事件信息 */
  event: IEventTrackingEvent
}

/**
 * 埋点的单独服务
 *    异步上报,非阻塞,报错无提示
 */
const eventTrackingService = axios.create({
  baseURL: '/api',
  withCredentials: true
})

interface IPostEventTrackingReq {
  reports: IPostEventTrackingReport[]
}

/** 事件上报接口 */
export const postEventTracking = (data: IPostEventTrackingReq) =>
  eventTrackingService({
    method: 'POST',
    url: '/datareport/upload',
    data
  })
