import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import './styles/index.less'
import RootRouter from './routes/RootRouter'
import stores from './stores'
import './utils/rem'
import Vconsole from 'vconsole'
import 'swiper/swiper.less'
import ErrorBoundary from '@/components/ErrorBoundary'
import sentry from '@/plugins/sentry'
if (
  window.location.hostname.includes('-dev.xiaoice.com') ||
  window.location.hostname.includes('-int.xiaoice.com') ||
  window.location.hostname.includes('localhost')
) {
  const vConsole = new Vconsole()
}

sentry.init?.()

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <ErrorBoundary>
        <RootRouter />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
