const crypto = require('crypto')
export function getMd5_16(str: string) {
  str = str.toLowerCase()
  const md5 = crypto.createHash('md5').update(str).digest('hex')
  const md5_16 = md5.toLowerCase().substring(8, 24)
  return md5_16
}

export function isMobile() {
  const device = navigator.userAgent.toLowerCase()
  const reg = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/
  const isMobile = reg.test(device)
  console.log('isMobile', isMobile)
  return isMobile
}
