import { RouteProps } from 'react-router-dom'
import { lazy } from 'react'
import configData from '@/config'
const HomePage = lazy(() => import(/* webpackChunkName: 'HomePage' */ '@/pages/home/index'))
const AttentionVideo = lazy(() => import(/* webpackChunkName: 'AttentionVideo' */ '@/pages/home/attentionVideo'))
const MakeVideo = lazy(() => import(/* webpackChunkName: 'MakeVideo' */ '@/pages/home/makeVideo'))
const LoginPage = lazy(() => import(/* webpackChunkName: 'LoginPage' */ '@/pages/login/index'))
const CodePage = lazy(() => import(/* webpackChunkName: 'CodePage' */ '@/pages/login/code'))
const Custom = lazy(() => import(/* webpackChunkName: 'Custom' */ '@/pages/home/custom'))
const ForgetPage = lazy(() => import(/* webpackChunkName: 'ForgetPage' */ '@/pages/login/forget'))
const ActivePage = lazy(() => import(/* webpackChunkName: 'ActivePage' */ '@/pages/login/active'))
const MyPage = lazy(() => import(/* webpackChunkName: 'Mypage' */ '@/pages/home/my'))
const TrainVideo = lazy(() => import(/* webpackChunkName: 'TrainVideo' */ '@/pages/home/trainVideo'))
const ChangeVoice = lazy(() => import(/* webpackChunkName: 'ChangeVoice' */ '@/pages/home/changeVoice'))
const VideoPage = lazy(() => import(/* webpackChunkName: 'VideoPage' */ '@/pages/home/videoPage'))
const ChangeVoiceType = lazy(() => import(/* webpackChunkName: 'ChangeVoiceType' */ '@/pages/home/changeVoiceType'))
const AttentionVoice = lazy(() => import(/* webpackChunkName: 'AttentionVoice' */ '@/pages/home/attentionVoice'))
const AttentionVoiceOfVideo = lazy(
  () => import(/* webpackChunkName: 'AttentionVoiceOfVideo' */ '@/pages/home/attentionVoiceOfVideo')
)
const MakeAudio = lazy(() => import(/* webpackChunkName: 'MakeAudio' */ '@/pages/home/makeAudio'))
const VideoConfigProgress = lazy(
  () => import(/* webpackChunkName: 'VideoConfigProgress' */ '@/pages/home/videoConfigProgress')
)
const VideoConfigSuccess = lazy(
  () => import(/* webpackChunkName: 'VideoConfigSuccess' */ '@/pages/home/videoConfigSuccess')
)
const AudioConfigSuccess = lazy(
  () => import(/* webpackChunkName: 'AudioConfigSuccess' */ '@/pages/home/audioConfigSuccess')
)
const VideoAudioList = lazy(() => import(/* webpackChunkName: 'VideoAudioList' */ '@/pages/home/videoAudioList'))
const VideoGenerateText = lazy(
  () => import(/* webpackChunkName: 'VideoGenerateText' */ '@/pages/home/videoGenerateText')
)
const VideoGenerateProcess = lazy(
  () => import(/* webpackChunkName: 'VideoGenerateProcess' */ '@/pages/home/videoGenerateProcess')
)
const VideoGenerateName = lazy(
  () => import(/* webpackChunkName: 'VideoGenerateName' */ '@/pages/home/videoGenerateName')
)
const VideoGenerateSuccess = lazy(
  () => import(/* webpackChunkName: 'VideoGenerateSuccess' */ '@/pages/home/videoGenerateSuccess')
)
const VideoSettled = lazy(() => import(/* webpackChunkName: 'VideoSettled' */ '@/pages/home/videoSettled'))
const AudioSettled = lazy(() => import(/* webpackChunkName: 'AudioSettled' */ '@/pages/home/audioSettled'))
const AudioConfigProgress = lazy(
  () => import(/* webpackChunkName: 'AudioConfigProgress' */ '@/pages/home/audioConfigProgress')
)
const AudioGenerateName = lazy(
  () => import(/* webpackChunkName: 'AudioGenerateName' */ '@/pages/home/audioGenerateName')
)
const AudioGenerateSuccess = lazy(
  () => import(/* webpackChunkName: 'AudioGenerateSuccess' */ '@/pages/home/audioGenerateSuccess')
)
const CreatePwdPage = lazy(() => import(/* webpackChunkName: 'CreatePwdPage' */ '@/pages/password/create'))
const ResetPwdPage = lazy(() => import(/* webpackChunkName: 'ResetPwdPage' */ '@/pages/password/reset'))
const ResetPwdExpiredPage = lazy(
  () => import(/* webpackChunkName: 'ResetPwdExpiredPage' */ '@/pages/password/reset-expire')
)
const ServiceTerms = lazy(() => import(/* webpackChunkName: 'ServiceTerms' */ '@/pages/law/service-terms'))
const PrivacyPoliciest = lazy(() => import(/* webpackChunkName: 'PrivacyPoliciest' */ '@/pages/law/privacy-policiest'))
const VideoAgreement = lazy(() => import(/* webpackChunkName: 'VideoAgreement' */ '@/pages/law/video-agreement'))
const AudioAgreement = lazy(() => import(/* webpackChunkName: 'AudioAgreement' */ '@/pages/law/audio-agreement'))
const CustomAgreement = lazy(() => import(/* webpackChunkName: 'CustomAgreement' */ '@/pages/law/CustomAgreement'))
const AboutUs = lazy(() => import(/* webpackChunkName: 'AboutUs' */ '@/pages/home/my/components/AboutUs'))
const ReleaseNotice = lazy(() => import(/* webpackChunkName: 'AudioAgreement' */ '@/pages/law/releaseNotice'))
const RecordingPrecautions = lazy(
  () => import(/* webpackChunkName: 'RecordingPrecautions' */ '@/pages/home/recordingPrecautions')
)
const CreatePwdExpiredPage = lazy(
  () => import(/* webpackChunkName: 'CreatePwdExpiredPage' */ '@/pages/password/create-expire')
)
const VideoUpload = lazy(() => import(/* webpackChunkName: 'VideoUpload' */ '@/pages/home/VideoUpload'))
const UploadPrecautions = lazy(
  () => import(/* webpackChunkName: 'UploadPrecautions' */ '@/pages/home/uploadPrecautions')
)
const AuthComponent = lazy(() => import(/* webpackChunkName: 'AuthComponent' */ '@/pages/auth'))
const VideoRemoveConfirm = lazy(() => import(/* webpackChunkName: 'RemoveSuccess' */ '@/pages/home/videoRemoveConfirm'))
const VideoRemoveFinish = lazy(() => import(/* webpackChunkName: 'RemoveSuccess' */ '@/pages/home/videoRemoveFinish'))
const CuRecordingPrecautions = lazy(
  () => import(/* webpackChunkName: 'RecordingPrecautions' */ '@/pages/home/cuRecordingPrecautions')
)
const KnowledgeBaseDocumentIntroduceComponent = lazy(() => import(/* webpackChunkName: 'AuthComponent' */ '@/pages/introduce/knowledgeBaseDocument'))

export interface RouteOptions extends RouteProps {
  routes?: RouteOptions[]
  auth?: boolean
  redirect?: string
}
export const routes: RouteOptions[] = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomePage,
    redirect: '/home/custom',
    auth: true,
    routes: [
      {
        path: '/home/custom',
        component: Custom,
        exact: true
      },
      {
        path: '/home/makeVideo',
        component: MakeVideo,
        exact: true
      },
      {
        path: '/home/my',
        component: MyPage,
        auth: true
      },
      {
        path: '/home/attentionVideo',
        component: AttentionVideo,
        auth: true
      },
      {
        path: '/home/trainVideo',
        component: TrainVideo,
        auth: true
      },
      {
        path: '/home/changeVoice',
        component: ChangeVoice,
        auth: true
      },
      {
        path: '/home/videoPage',
        component: VideoPage,
        auth: true
      },
      {
        path: '/home/changeVoiceType',
        component: ChangeVoiceType,
        auth: true
      },
      {
        path: '/home/attentionVoice',
        component: AttentionVoice,
        auth: true
      },
      {
        path: '/home/attentionVoiceOfVideo',
        component: AttentionVoiceOfVideo,
        auth: true
      },
      {
        path: '/home/makeAudio',
        component: MakeAudio,
        auth: true
      },
      {
        path: '/home/videoConfigProgress',
        component: VideoConfigProgress,
        auth: true
      },
      {
        path: '/home/audioConfigProgress',
        component: AudioConfigProgress,
        auth: true
      },
      {
        path: '/home/videoConfigSuccess',
        component: VideoConfigSuccess,
        auth: true
      },
      {
        path: '/home/audioConfigSuccess',
        component: AudioConfigSuccess,
        auth: true
      },
      {
        path: '/home/videoAudioList',
        component: VideoAudioList,
        auth: true
      },
      {
        path: '/home/videoGenerateText',
        component: VideoGenerateText,
        auth: true
      },
      {
        path: '/home/videoGenerateProcess',
        component: VideoGenerateProcess,
        auth: true
      },
      {
        path: '/home/videoGenerateName',
        component: VideoGenerateName,
        auth: true
      },
      {
        path: '/home/audioGenerateName',
        component: AudioGenerateName,
        auth: true
      },
      {
        path: '/home/videoGenerateSuccess',
        component: VideoGenerateSuccess,
        auth: true
      },
      {
        path: '/home/audioGenerateSuccess',
        component: AudioGenerateSuccess,
        auth: true
      },
      {
        path: '/home/videoSettled',
        component: VideoSettled,
        auth: true
      },
      {
        path: '/home/audioSettled',
        component: AudioSettled,
        auth: true
      },
      {
        path: '/home/about-us',
        component: AboutUs,
        auth: true
      },
      /**
       * 视频录制注意事项
       */
      {
        path: '/home/videoPrecautions',
        component: !configData?.isCU ? RecordingPrecautions : CuRecordingPrecautions,
        auth: true
      },
      {
        path: '/home/videoUpload',
        component: VideoUpload,
        auth: true
      },

      {
        path: '/home/uploadPrecautions',
        component: UploadPrecautions,
        auth: true
      },
      {
        path: '/home/videoRemoveConfirm',
        component: VideoRemoveConfirm,
        auth: true
      },
      {
        path: '/home/videoRemoveFinish',
        component: VideoRemoveFinish,
        auth: true
      }
    ]
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true
  },
  {
    path: '/code',
    component: CodePage,
    exact: true,
  },
  {
    path: '/forget-pass',
    component: ForgetPage,
    exact: true
  },
  {
    path: '/password/active',
    component: ActivePage,
    exact: true
  },
  {
    path: '/password/create',
    component: CreatePwdPage,
    exact: true
  },
  {
    path: '/password/reset',
    component: ResetPwdPage,
    exact: true
  },
  {
    path: '/password/reset-expired',
    component: ResetPwdExpiredPage,
    exact: true
  },
  {
    path: '/law/service-terms',
    component: ServiceTerms,
    exact: true
  },
  {
    path: '/law/privacy-policiest',
    component: PrivacyPoliciest,
    exact: true
  },
  {
    path: '/law/video-agreement',
    component: VideoAgreement,
    exact: true
  },
  {
    path: '/law/audio-agreement',
    component: AudioAgreement,
    exact: true
  },
  // 快速定制规则
  {
    path: '/law/custom-agreement',
    component: CustomAgreement,
    exact: true
  },
  {
    path: '/password/create-expired',
    component: CreatePwdExpiredPage,
    exact: true
  },
  /**APP跳转认证*/
  {
    path: '/auth',
    component: AuthComponent,
    exact: true
  },
  {
    path: '/law/release-notice',
    component: ReleaseNotice,
    exact: true
  },
   /**知识库文档说明*/
   {
    path: '/lintroduce/knowledge-base-document',
    component: KnowledgeBaseDocumentIntroduceComponent,
    exact: true
  },
]
