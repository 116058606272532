import { useContext } from 'react'
import { MobXProviderContext } from 'mobx-react'

import user from './user'
import configText from './configText'

const store = {
  configText,
  user
}

export default store

export function useStore<T extends keyof typeof store>(name: T) {
  return useContext<Record<string, typeof store[typeof name]>>(MobXProviderContext)[name]
}
